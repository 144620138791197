import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Card from './Card'
import { Parallax } from 'react-scroll-parallax'

const PostGroup = ({ row, previousLocation, location }) => {
  const [parallaxToggle, setParallaxToggle] = useState(true)

  const handleParallax = mediaQuery => {
    if (mediaQuery.matches) {
      setParallaxToggle(false)
    } else {
      setParallaxToggle(true)
    }
  }

  useEffect(() => {
    const width = window.matchMedia('(max-width: 700px)')
    handleParallax(width)
    width.addListener(handleParallax)

    return () => {
      width.removeListener(handleParallax)
    }
  }, [])

  const renderCard = (post, previousLocation, location) => {
    const heroSection =
      post.node.sections.find(
        section => section.__typename === 'ContentfulPostHero'
      ) || {}

    const titleSection =
      post.node.sections.find(
        section => section.__typename === 'ContentfulPostTitle'
      ) || {}

    const bodySection =
      post.node.sections.find(
        section => section.__typename === 'ContentfulPostBody'
      ) || {}

    return (
      <Card
        key={post.node.id}
        fullPost={post.node.fullPost}
        slug={post.node.slug}
        heroImage={heroSection?.heroImage || null}
        title={titleSection?.title || 'No title'}
        publishDate={post.node.publishDate}
        body={bodySection?.bodyParagraph || 'No body'}
        tags={post.node.tags || []}
        hoverDescription={heroSection?.hoverDescription || ''}
        hoverMedia={heroSection?.hoverMedia || null}
        {...post.node}
        previousLocation={previousLocation}
        location={location}
      />
    )
  }

  // const renderCard = (post, previousLocation, location) => (
  //   <Card
  //     key={post.node.id}
  //     fullPost={post.node.fullPost}
  //     slug={post.node.slug}
  //     heroImage={
  //       post.node.sections.find(
  //         section => section.__typename === 'ContentfulPostHero'
  //       ).heroImage
  //     }
  //     title={post.node.sections.find(
  //       section => section.__typename === '"ContentfulPostTitle"'
  //     )}
  //     publishDate={post.node.publishDate}
  //     body={
  //       post.node.sections.find(
  //         section => section.__typename === 'ContentfulPostBody'
  //       ).bodyParagraph
  //     }
  //     tags={post.node.tags}
  //     hoverDescription={
  //       post.node.sections.find(
  //         section => section.__typename === 'ContentfulPostHero'
  //       ).hoverDescription
  //     }
  //     hoverMedia={
  //       post.node.sections.find(
  //         section => section.__typename === 'ContentfulPostHero'
  //       ).hoverMedia
  //     }
  //     {...post.node}
  //     previousLocation={previousLocation}
  //     location={location}
  //   />
  // )

  return (
    <PostGroupContainer id={`postGroupContainer`}>
      {row.map((post, i) => {
        if (i === 1 || i === 3) {
          return (
            <li className={`post${i} allPosts`} key={i}>
              <Parallax y={parallaxToggle ? [125, 25] : [0, 0]}>
                {renderCard(post, previousLocation, location)}
              </Parallax>
            </li>
          )
        } else {
          return (
            <li className={`post${i} allPosts`} key={i}>
              {renderCard(post, previousLocation, location)}
            </li>
          )
        }
      })}
    </PostGroupContainer>
  )
}

export default PostGroup

const PostGroupContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;

  li {
    width: calc(100% / 3 - 2%);
    margin: 0 0 6vw 0;

    transition: all 0.6s;
  }

  li.post0 {
    .gatsby-image-wrapper {
      div:first-of-type {
        padding-bottom: 63% !important;
        height: 0;
      }
    }
  }

  li:first-child {
    width: calc(100% * 2 / 3 - 1%);

    .postContainer {
      position: relative;
    }
  }

  li:nth-child(2) {
    margin-right: 3%;
    position: relative;
    z-index: 10;

    .postContainer {
      position: relative;
    }
  }

  li:nth-child(3) {
    order: 4;

    margin-right: 1.5%;
  }

  li:nth-child(4) {
    order: 3;

    margin-left: 1.5%;
    position: relative;
    z-index: 10;
  }

  &:nth-child(2n) {
    flex-direction: row;

    li:nth-child(2) {
      margin-right: 0;
      margin-left: 3%;
      z-index: 10;
    }

    li:nth-child(3) {
      order: 4;

      margin-right: 0;
      margin-left: 1.5%;
    }

    li:nth-child(4) {
      order: 3;

      margin-left: 0;
      margin-right: 1.5%;
      z-index: 10;
    }
  }

  @media (max-width: 700px) {
    flex-direction: column !important;
    align-items: flex-start !important;

    li,
    li:first-child,
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4) {
      width: 100% !important;
      margin: 0 !important;
      padding: 28px 0;
    }
  }
`
