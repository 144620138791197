import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import BlockEffect from './riseEffect/BlockEffect'
import arrow from '../assets/images/arrow6.png'
import TextEffect from '../components/riseEffect/TextEffect'
import Media from '../components/Media'
import gsap from 'gsap'

const Services = ({ services }) => {
  let textRefs = []
  let dashRefs = []
  let arrowRefs = []
  let titleRefs = []

  for (let i = 0; i < services.length; i++) {
    textRefs.push(useRef(null))
    dashRefs.push(useRef(null))
    arrowRefs.push(useRef(null))
    titleRefs.push(useRef(null))
  }

  const handleClick = node => {
    if (textRefs[node].style.maxHeight) {
      contract(node)
    } else {
      expand(node)
    }
  }

  const handleMaxHeight = mediaQuery => {
    if (mediaQuery.matches) {
      // for each service, if max height exists (is expanded), then reset it to the new max height
      textRefs.forEach(node => {
        if (node.style.maxHeight) {
          node.style.maxHeight = node.scrollHeight + 'px'
        }
      })
    }
  }

  useEffect(() => {
    const resetMaxHeight = window.matchMedia(
      '(min-width: 700px) and (max-width: 840px), (min-width: 1150px) and (max-width: 1550px)'
    )

    handleMaxHeight(resetMaxHeight)

    resetMaxHeight.addListener(handleMaxHeight)

    return () => {
      resetMaxHeight.removeListener(handleMaxHeight)
    }
  }, [])

  const contract = node => {
    textRefs[node].style.maxHeight = null
    textRefs[node].style.marginTop = null

    arrowRefs[node].style.transform = 'rotate(90deg)'
    dashRefs[node].style.transform = 'scaleX(0)'
    titleRefs[node].style.transform = 'translateY(5px)'
  }

  const expand = node => {
    textRefs[node].style.maxHeight = textRefs[node].scrollHeight + 'px'
    textRefs[node].style.marginTop = '30px'

    arrowRefs[node].style.transform = 'rotate(270deg)'
    titleRefs[node].style.transform = 'translate(3vw, 5px)'
    dashRefs[node].style.transform = 'scaleX(1)'
  }

  const ServicesList = styled.ul`
    margin: 20px 0;

    .titleContainer {
      display: flex;
      justify-content: space-between;
    }

    .arrowContainer {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        transform: rotate(90deg);

        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      .arrowUp {
        transform: rotate(270deg);
      }
    }

    li {
      border-top: 1px solid white;
      padding: 30px 0;

      cursor: pointer;

      .serviceContainer {
        display: flex;
        align-items: center;
      }

      .dash {
        background-color: white;
        width: 5vw;
        transform: scaleX(0);
        transform-origin: left;
        height: 3px;
        margin-right: 0vw;
        transition: all 0.6s;
      }

      .dashClicked {
        transform: scaleX(1);
        transform-origin: left;
      }

      .titleClicked {
        transform: translate(3vw, 5px);
      }

      h3 {
        font-size: 5.5vw;
        transform: translateY(5px);
        transition: all 0.6s;
        text-transform: uppercase;
      }

      &:hover {
        .dash {
          transform: scaleX(1);
          transform-origin: left;
        }

        h3 {
          transform: translate(3vw, 5px);
        }
      }

      @media (pointer: coarse), (pointer: none) {
        &:hover {
          .dash {
            transform: scaleX(0);
            transform-origin: left;
          }

          h3 {
            transform: translate(0, 0);
          }
        }
      }

      .serviceText {
        display: flex;
        padding-top: 0px;
        max-height: 0;
        overflow-y: hidden;
        padding-top: 0px;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
      }

      .textContainer {
        display: flex;
      }

      .headline {
        width: 350px;
        font-size: 1.3rem;
        font-weight: 100;

        p {
          line-height: 1.3;
        }
      }

      .description {
        width: 400px;
        margin-left: 4vw;

        p {
          line-height: 1.6;
        }
      }

      .serviceImage {
        display: block;
        width: calc(100% - 800px - 8vw);
        margin-left: 4vw;

        .gatsby-image-wrapper {
          div:first-child {
            padding-bottom: 63% !important;
          }
        }
      }
    }

    li:last-child {
      border-bottom: 1px solid white;
    }

    @media (max-width: 1550px) {
      li {
        .serviceText {
          margin-left: 8vw;
        }

        .textContainer {
          flex-direction: column;
        }

        .headline,
        .description {
          width: 400px;
        }

        .description {
          margin-left: 0;
          margin-top: 20px;
        }

        .serviceImage {
          width: calc(100% - 400px - 8vw);
        }
      }
    }

    @media (max-width: 1150px) {
      li {
        .headline,
        .description {
          width: 320px;
        }

        .serviceImage {
          width: calc(100% - 340px - 8vw);
        }
      }
    }

    @media (max-width: 840px) {
      li {
        h3 {
          transform: translateY(5px);
          transition: all 0.6s;
        }

        .headline {
          font-size: 1.2rem;
        }

        .description {
          font-size: 0.9rem;
        }

        .serviceText {
          display: block;
        }

        .headline,
        .description {
          width: 90%;
        }

        .serviceImage {
          width: 90%;
          margin-left: 0;
          margin-top: 35px;
        }
      }
    }

    @media (max-width: 700px) {
      li {
        .serviceContainer {
          width: calc(100% - 50px);
        }

        .serviceText {
          margin-left: 3vw;
        }

        .dash {
          display: none;
        }

        h3 {
          font-size: 6.5vw;
        }

        .headline {
          font-size: 1.2rem;
        }

        .description {
          font-size: 0.9rem;
        }
      }
    }

    @media (min-width: 500px) {
      .arrowContainer {
        width: 25px;
      }
    }

    @media (min-width: 1800px) {
      li {
        h3 {
          font-size: 6rem;
        }
      }
    }
  `

  const ServicesContainer = styled.div`
    padding: 7.5vh 0;

    h2 {
      font-weight: 500;
      font-family: Helvetica, serif !important;
    }
  `

  return (
    <ServicesContainer id={`services`} name="services">
      <h2>Services</h2>
      <ServicesList>
        {services.map(({ node }, i) => {
          return (
            <li key={node.id} onClick={() => handleClick(i)}>
              <div className="titleContainer">
                <div className="serviceContainer">
                  <div
                    className="dash"
                    id={`${node.title} Dash`}
                    ref={div => (dashRefs[i] = div)}
                  ></div>
                  <h3
                    id={`${node.title} Title`}
                    ref={h3 => (titleRefs[i] = h3)}
                  >
                    <BlockEffect
                      to={150}
                      content={node.title}
                      lineHeight={1.2}
                      y={0}
                      delay={0.2}
                    />
                  </h3>
                </div>
                <div className="arrowContainer">
                  <img
                    src={arrow}
                    alt="arrow"
                    id={`${node.title} Arrow`}
                    ref={img => (arrowRefs[i] = img)}
                  />
                </div>
              </div>
              <div
                className="serviceText"
                id={`${node.title} Text`}
                ref={div => (textRefs[i] = div)}
              >
                <div
                  className="textContainer"
                  // ref={div => (descriptionRefs[i] = div)}
                >
                  <div className="headline">
                    <TextEffect lineHeight={1.3} text={node.headline} />
                  </div>

                  <div className="description">
                    <TextEffect
                      lineHeight={1.4}
                      text={node.description.description}
                    />
                  </div>
                </div>

                <div
                  className="serviceImage"
                  // ref={div => (imageRefs[i] = div)}
                >
                  <Media
                    videoCheck={node.media.file.url.slice(-3)}
                    videoSrcURL={node.media.file.url}
                    alt={node.media.title}
                    title={node.media.title}
                    description={node.media.description}
                    fluid={node.media.fluid}
                  />
                </div>
              </div>
            </li>
          )
        })}
      </ServicesList>
    </ServicesContainer>
  )
}

export default Services
