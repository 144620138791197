import styled from '@emotion/styled'
import React, { useRef, useEffect, createRef } from 'react'
import state from './scrollEffect/HeroState'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import TextEffect from './riseEffect/TextEffect'
import HeroTitle from './HeroTitle'
import { TweenMax, gsap } from 'gsap'
import Warp from './scrollEffect/Warp'
import { useSpring, animated } from '@react-spring/web'

const Hero = ({ hero, hideLoader, title }) => {
  const description =
    hero?.rightDescription?.rightDescription || 'Descrição não disponível'

  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]

  const trans0 = (x, y) => `translate3d(${-x / 11}px,${-y / 14}px,0)`
  const trans1 = (x, y) => `translate3d(${-x / 28}px,${-y / 31}px,0)`
  const trans2 = (x, y) => `translate3d(${-x / 20}px,${-y / 23}px,0)`

  const transArr = [trans0, trans1, trans2]

  let canvasRef1 = useRef(null)
  let canvasRef2 = useRef(null)
  let canvasRef3 = useRef(null)

  const canvasRefContainer = [canvasRef1, canvasRef2, canvasRef3]

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }))

  let scrollRef = useRef(null)
  let heroHeight

  const handleResize = () => {
    heroHeight = document.getElementById('heroContainer').scrollHeight
  }

  useEffect(() => {
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y >= -heroHeight) {
        return (state.top.current = currPos.y * -0.5)
      }
    },
    [],
    scrollRef
  )

  const handleHover = id => {
    const images = document.getElementsByClassName('canvasContainer')

    for (let i = 0; i < images.length; i++) {
      if (images[i].id === id) {
        TweenMax.to(canvasRefContainer[i], {
          opacity: 1,
          duration: 1,
        })
      } else {
        TweenMax.to(canvasRefContainer[i], {
          opacity: 0.3,
          duration: 1,
        })
      }
    }
  }

  let canvasListRef = useRef(null)

  const showCanvas = () => {
    gsap.to(canvasListRef, {
      opacity: 1,
      y: 0,
      duration: 1.4,
      delay: 0.4,
      ease: 'power2.out',
    })
  }

  return (
    <HeroContainer
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      ref={scrollRef}
      id="heroContainer"
    >
      <div className="titleContainer">
        <HeroTitle
          description={
            hero?.description?.description || 'Description not available'
          }
        />
      </div>

      <div className="scrollContainer" id={`scrollContainer`}>
        <div className="galleryContainer">
          <ul
            className="canvasList"
            id={`canvasList`}
            ref={ul => {
              canvasListRef = ul
            }}
          >
            {hero?.heroGallery?.map((image, i) => {
              return (
                <animated.li
                  className={`canvasContainer canvasContainer${i}`}
                  key={i}
                  id={`canvasContainer${i}`}
                  ref={li => (canvasRefContainer[i] = li)}
                  style={{
                    transform: props.xy.interpolate(transArr[i]),
                  }}
                  onMouseEnter={() => handleHover(`canvasContainer${i}`)}
                >
                  <Warp
                    image={image}
                    hideLoader={hideLoader}
                    showCanvas={showCanvas}
                  />
                </animated.li>
              )
            })}
          </ul>

          <div className="descriptionContainer">
            <div className="rightDescription">
              <TextEffect
                lineHeight={1.4}
                text={description}
                style={`lines`}
                delay={0.2}
              />
            </div>
          </div>
        </div>
      </div>
    </HeroContainer>
  )
}

export default Hero

const HeroContainer = styled.section`
    position: relative;
    padding-bottom: 22vw;

    .scrollContainer {
      position: relative;
    }

    .selectionPanels {
      position: absolute:
    }

    .galleryContainer {
      height: 92vh;
    }

    .canvasList {
      display: flex;
      justify-content: center;
      transform: translateY(40px);
    }

    .canvasContainer {
      height: 92vh;
      position: absolute;
      opacity: 0.3;
    }

    .canvasContainer0 {
      left: -21vw;
      top: 6vh;
      width: 42%;
    }

    .canvasContainer1 {
      left: 37%;
      width: 21%;
    }

    .canvasContainer2 {
      right: 0;
      top: 15vh;
      width: 23%;

    }

    .titleContainer {
      position: absolute;
      z-index: 100;
      pointer-events: none;

      top: 32%;
      left: 50%;

      transform: translate(-50%, 0);

      cursor: pointer;
    }

    h1 {
      font-size: 7.5vw;
      text-align: center;
      text-transform: uppercase;
      line-height: 0.9;
      margin: 0;
      white-space: nowrap;
      position: relative;

      .char {
        opacity: 0;
        display: inline-block;
      }
    }

    .descriptionContainer {
      position: absolute;
      bottom: -16vw;
      right: 0;
      display: flex;
      justify-content: flex-end;
    }

    .rightDescription {
      width: 44vw;
      font-size: 1.2vw;
      font-weight: 100;

      p {
        line-height: 1.3;
      }
    }

    .loading {
      color: white;
      padding: 10px;
      transform: translate3d(0, 0, 0);
      z-index: 100;
      width: 100%;
      height: 100%;
      background-color: #1c1c20;
      font-size: 1rem;
      font-family: 'NeueHaasGrotesk', 'SuisseIntl', sans-serif;
      font-weight: 300;
    }

    @media (max-width: 1350px) {

      padding-bottom: 16vw;

      .descriptionContainer {
        bottom: -11vw;
      }

       .canvasContainer0 {
        top: -2vh;
      }

      .rightDescription {
        font-size: 1.1rem;
      }

      h1 {
        font-size: 8.5vw;
      }
    }

    @media (max-width: 1050px) {

      .descriptionContainer {
        bottom: -10vw;
      }

      .canvasContainer {
        height: 83vh;
      }

      .canvasContainer0 {
        left: -31vw;
        width: 56%;
      }

      .canvasContainer1 {
        left: 36%;
        width: 29%;
      }

      .canvasContainer2 {
        right: -14vw;
        top: 15vh;
        width: 35%;
      }


      .rightDescription {
        font-size: 1rem;
                width: 61vw;

      }
    }

    @media (max-width: 840px) {

      padding-bottom: 13vw;

      .descriptionContainer {
        bottom: -1vw;
      }

      .rightDescription {
        width: 69vw;
      }

      .canvasContainer {
        height: 72vh;
      }

      .canvasContainer0 {
        left: -35vw;
        width: 62%;
      }

      .canvasContainer1 {
        left: 35%;
        width: 35%;
      }

      .canvasContainer2 {
        right: -24vw;
        width: 43%;
      }




    }

    @media (max-width: 700px) {

      padding-bottom: 11vw;

      .canvasContainer {
        height: 74vh;
      }

      .canvasContainer0 {
        left: -7vw;
        width: 63%;
      }

      .canvasContainer1 {
        left: 74%;
        width: 43%;
      }

      .canvasContainer2 {
        opacity: 0 !important;
        pointer-events: none;
      }

      .galleryContainer {
      height: 78vh;
    }

    .descriptionContainer {
      right: auto;
    }

    .rightDescription {
      width: 100%;
    }


    }

    @media (max-width: 550px) {


    }

    @media (max-width: 450px) {

      padding-bottom: 16vw;

      h1 {
      font-size: 15.5vw;
      text-align: right;

      }


      .galleryContainer {
        height: 70vh;
        min-height: 632px;
      }

      .canvasContainer {
        height: 66vh;
        min-height: 485px;
      }

      .canvasContainer0 {
        left: -33vw;
        width: 89%;
      }

      .canvasContainer1 {
        left: 72%;
        width: 55%;
      }

      .rightDescription {
      width: 100%;
      font-size: 0.9rem;
    }



    }

    @media (min-width: 1800px) {
      h1 {
      font-size: 8.5rem;

    }


    }



  `
